<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12"> <Header /> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <UserLogin />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from "@/components/Header";
import UserLogin from "@/components/user/UserLogin";

export default {
  components: {
    Header,
    UserLogin,
  },

  mounted() {
    this.$store.dispatch("menu/setQuickLink", "login");
  },
};
</script>